.s-ntbs {
  position: fixed;
  right: 0;
  top: calc(63% + 25px);
  transform: translateX(0);
  z-index: 3;
}

.s-ntbs button {
  background-color: #fff;
  box-shadow: 0 0 9.8px 0.2px rgba(0, 0, 0, 0.1);
  height: 42px;
  width: 42px;
  border: none;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

button:hover {
  cursor: pointer;
}

.s-ntbs button:hover {
  background-color: #ff1949;
}

.hov-svg:hover svg {
  fill: #fff;
}

.hov-path:hover path {
  stroke: #fff;
}

.review-ntb {
  position: fixed;
  top: calc(63% + 25px);
  transform: rotate(-90deg);
  left: 0;
  width: 30px;
  z-index: 2;
}

.review-ntb a {
  text-decoration: none;
}

.review-ntb button {
  background-color: #ff1949;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-ntb button svg {
  margin-right: 5px;
}

.top-badge {
  text-align: center;
  padding: 8px 0px;
  width: 100%;
  line-height: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff1949;
}

.logo-web-header {
  height: 75px;
}

.logo-web-header img {
  height: 100%;
}

.nav-moed {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 20px 0px;
  z-index: 9;
}

.navbar-nav .nav-link {
  color: rgba(37, 37, 37, .6);
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 19px;
  letter-spacing: -.2px;
  padding: 3px 15px !important;
}

.navbar-nav .nav-item.active .nav-link {
  font-weight: 700;
  color: #ff1949 !important;
}

.nav-item {
  display: flex;
  align-items: center;
  transition: all .4s cubic-bezier(.46, .03, .52, .96);
}

.nav-item button {
  background-color: transparent;
  border-radius: 50px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  border: none;
  outline: none;
  position: relative;
}

.cart-munber {
  width: 19px;
  height: 19px;
  background-color: #ff1949;
  font-family: "Montserrat", Sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 19px;
  bottom: -5px;
  right: -5px;
  padding: 0;
  position: absolute;
  top: -4px;
  border-radius: 3px;
  color: #fff;
}

.a,
.c {
  fill: none;
}

.a {
  stroke: #252525;
  stroke-linecap: round;
  stroke-width: 1.5px;
}

.b {
  stroke: none;
}

.justify-content-right {
  justify-content: right !important;
}

header {
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.acart {
  fill: #252525;
}

.li-nav-cino {
  display: flex;
  justify-content: space-between;
}

.label-nav-item span {
  position: absolute;
  height: 18px;
  background-color: #ff1949;
  font-family: "Montserrat", Sans-serif;
  font-size: 9px;
  font-weight: 700;
  line-height: 19px;
  bottom: -5px;
  right: -5px;
  padding: 0px 5px;
  top: -9px;
  border-radius: 3px;
  color: #fff;
}

.label-nav-item {
  position: relative;
}

.navbar-toggler span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ff1949' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.slider-video {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: hidden;
}

.slider-video .progrees-vide {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  transition: 4s;
  height: 2px;
  background-color: #ff1949;
}

.w-100-per {
  width: 100% !important;
}

.slider-video video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mvh-secfooter {
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  line-height: Normal;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.svg-mvh-sec {
  margin-left: 7px;
  height: 40px;
  padding-top: 5px;
}

.mvh-secfooter svg {
  margin-left: 7px;
}

.dtrp-secfooter {
  font-family: "Montserrat", Sans-serif;
  font-weight: 400;
  line-height: Normal;
  font-size: 22px;
}

.paperx-box {
  overflow: hidden;
  max-width: 291.75px;
  margin: 20px 0px;
  margin-right: 16px;
  border: rgba(37, 37, 37, .2)solid 2px;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  transition: border-color .2s ease-out;
}

.paperx-box:hover {
  border-color: transparent;
}

.paper-product {
  height: 100%;
  width: 100%;
}

.paper-img-prod {
  border-radius: 1.5rem;
  background-color: rgb(248 250 252/1);
  overflow: hidden;
}

.paper-prod-naem {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.paper-prod-para {
  color: rgb(100 116 139/1);
  font-family: "Montserrat", sans-serif;
  font-size: .875rem;
  line-height: 1.25rem;
}

.price-prod-paperx {
  font-family: "Montserrat", sans-serif;
  color: #ff1949;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.0rem;
  border: #ff1949 solid 2px;
  border-radius: 0.5rem;
  width: fit-content;
  min-width: 40px;
}

.price-prod-paperx span {
  margin-right: 4px;
}

.prod-review-paperx {
  display: flex;
  color: rgb(100 116 139/1);
  font-size: .875rem;
  line-height: 1.25rem;
  font-family: "Montserrat", sans-serif;
  margin-left: 0.25rem;
}

.prod-review-paperx svg {
  color: rgb(251 191 36/1);
  width: 1.25rem;
  height: 1.25rem;
}

.prod-review-paperx span {
  margin: 0px 4px;
}

.qodef-woo-svg {
  position: absolute;
  border-radius: 0px;
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  transition: opacity .1s ease-out;
  opacity: 0;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.image-box-insta {
  height: 200px;
  width: 200px;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.image-box-insta img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: .3s;
}

@media only screen and (max-width: 600px) {
  .paperx-product-swipe .col-slide-pb {
    display: flex;
    justify-content: center;
  }

  .payboxes-mar .vid-bixox {
    display: flex;
    justify-content: center;
  }

  .paperx-product-swipe .swiper-slide, .insta-swipe .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .image-box-insta {
    height: 300px;
    width: 300px;
  }

  .payboxes-mar {
    justify-content: center;
  }
}

.paperx-box:hover .qodef-woo-svg {
  opacity: 1;
}

.qodef-woo-svg rect {
  animation: woo-qodef-svg-animation 5s linear infinite;
}

@keyframes woo-qodef-svg-animation {
  0% {
    stroke-dashoffset: 0
  }

  100% {
    stroke-dashoffset: 100%
  }
}

.paperx-box:hover .pap-btns-cart {
  transform: translateX(0px);
}

.pap-btns-cart {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: translateX(200px);
  display: grid;
  transition: .5s;
}

.pap-btns-cart button {
  background-color: #fff;
  border: none;
  height: 35px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 35px;
  margin-bottom: 10px;
}

.added-to-cart path {
  fill: #ff1949 !important;
}

.added-to-whish path {
  fill: #ff1949 !important;
  stroke: #ff1949 !important;
}

.mvh-ul-foot {
  list-style: none;
  padding-left: 0;
}

.mvh-ul-foot li {
  margin-bottom: 10px;
  position: relative;
}

.mvh-ul-foot li::before {
  content: "";
  width: 6px;
  height: 7px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 1px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='7' viewBox='0 0 6 7' fill='none'%3E%3Cpath d='M5.23609 3.3741L1.77809 6.4961L0.952088 5.5581L3.24809 3.4301L0.840088 1.3441L1.73609 0.350098L5.23609 3.3741Z' fill='black'/%3E%3C/svg%3E%0A");
}

.mvh-ul-foot li a {
  font-family: "Montserrat", Sans-serif;
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.25s ease-in-out;
}

.mvh-ul-foot li a:hover {
  border-color: #000000;
}

.top-box-shadow {
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 7%);
}

.mt-20 {
  margin-top: 20px;
}

.csec-hd5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 20px;
}

.csec-par {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-top: 5px;
  padding-left: 2rem;
}

.csec-par a {
  text-decoration: none;
  color: #000;
}

.pl-1rem {
  padding-left: 2rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.box-con-frm {
  margin-bottom: 15px;
}

.lab-bcfrm label {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-top: 5px;
  user-select: none;
}

.box-con-frm input, textarea, select {
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  padding: 8px 10px;
  border: rgba(37, 37, 37, .6) solid 1px;
  width: 100%;
  border-radius: 7px;
  margin-top: 4px;
}

.slider_ntbs {
  margin-bottom: 100px;
  margin-top: 30px;
}

.slider_ntbs button {
  background: #ff1949;
  border-radius: 10px;
  padding: 15px 37px;
  box-shadow: 0 5px 30px rgba(255, 25, 73, .2);
  color: #fff;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -.6px;
  margin-right: 16px;
  border-color: transparent;
  min-width: 203px;
}

.graytntbs {
  border-color: rgba(37, 37, 37, .2) !important;
  background: transparent !important;
  color: #252525 !important;
  box-shadow: none !important;
}

.social-sldx {
  color: #252525;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -.7px;
}

.social-sldx ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.social-sldx ul li {
  margin: 0 17px;
  margin-bottom: 10px;
}

.social-sldx ul li a {
  color: #000;
}

.social-sldx ul li span svg {
  height: 19px;
  width: 19px;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.payboxes-mar .paperx-box {
  margin-top: 20px;
}

.video-box-paox {
  position: relative;
}

.video-paper-xo {
  border-radius: 7px;
}

.review-wraper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-box-paox .play-btn-paper {
  background-color: transparent;
  border-radius: 50px;
  pointer-events: all;
  height: 60px;
  width: 60px;
  text-align: -webkit-center;
  margin: auto auto;
  bottom: -110px;
  border: #fff solid 1px;
}

.video-box-paox:hover {
  border-color: #ff1949 !important;
}

.video-paper-xo video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.payboxes-mar .paperx-box .paper-img-prod {
  height: 270px;
  background-color: transparent;
}

.video-box-paox .play-btn-paper svg {
  height: 45px;
  width: 45px;
  fill: #fff;
  margin-left: 3px;
  margin-top: 1px;
}

.payboxes-mar .paperx-box .paper-img-prod img {
  object-fit: contain;
}

.itm-inst {
  height: 200px;
  width: 200px;
}

.itm-inst img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wrapper-insta {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(37, 37, 37, .45);
  height: 100%;
  width: 100%;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-insta svg {
  height: 30px;
  width: 30px;
}

.image-box-insta:hover .wrapper-insta {
  visibility: visible;
}

.about-para {
  font-size: 16px;
  font-family: "Montserrat", Sans-serif;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.psa-mxcontac {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  z-index: -10;
  overflow: hidden;
  margin-top: -50px;
}

.psa-mxcontac img {
  height: 100%;
  width: 100%;
}

.white-bg {
  background-color: #fff;
}

.section-padding {
  padding: 100px 0;
}

.cta-text>h1 {
  line-height: 52px;
  text-transform: inherit;
  font-family: "Montserrat", Sans-serif;
  margin-top: -6px;
}

.mb-45 {
  margin-bottom: 45px;
}

.theme-color {
  color: #ff1949;
}

.rt-btn2 {
  font-family: "Montserrat", Sans-serif;
  background-color: #ff214f;
  font-size: 12px;
  text-transform: uppercase;
  height: 48px;
  line-height: 48px;
  padding: 0 40px;
  transition: all .3s ease 0s;
  color: #fff;
  letter-spacing: 1px;
}

.rt-btn2:hover {
  border: #ff1949 solid 1px;
}

.column-center {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.client-img {
  border-radius: 0;
  height: 140px;
  background-color: transparent;
}

.client-img img {
  object-fit: contain;
}

.paping-quan {
  left: 10px;
  position: absolute;
}

.papimg-quan-abs {
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  font-size: 13px;
  background-color: #ff1949;
  color: #fff;
  margin-bottom: 5px;
  padding: 0px 20px 0px 10px;
  border-radius: 5px;
}

.totl-nod {
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.note-ship {
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.init-payment {
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  font-size: 12px;
}

.psr {
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

.profile-mig {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.profile-mig:hover {
  cursor: pointer;
}

.profile-mig input {
  height: 100%;
  width: 100%;
  display: none;
}

.profile-mig img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}